@font-face {
    font-family: 'Chirp';
    src: url('./chirp/Chirp-Heavy.eot');
    src: url('./chirp/Chirp-Heavy.eot?#iefix') format('embedded-opentype'),
        url('./chirp/Chirp-Heavy.woff2') format('woff2'),
        url('./chirp/Chirp-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Chirp';
    src: url('./chirp/Chirp-Bold.eot');
    src: url('./chirp/Chirp-Bold.eot?#iefix') format('embedded-opentype'),
        url('./chirp/Chirp-Bold.woff2') format('woff2'),
        url('./chirp/Chirp-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Chirp';
    src: url('./chirp/Chirp-Regular.eot');
    src: url('./chirp/Chirp-Regular.eot?#iefix') format('embedded-opentype'),
        url('./chirp/Chirp-Regular.woff2') format('woff2'),
        url('./chirp/Chirp-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Chirp';
    src: url('./chirp/Chirp-Medium.eot');
    src: url('./chirp/Chirp-Medium.eot?#iefix') format('embedded-opentype'),
        url('./chirp/Chirp-Medium.woff2') format('woff2'),
        url('./chirp/Chirp-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}