@import url("https://fonts.googleapis.com/css?family=Rowdies");
@import url("assets/fonts/fonts.css");
@import url("https://fonts.googleapis.com/css2?family=Palanquin:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Palanquin:wght@100;200;300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: 'Chirp',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #052711;
}

code {
  font-family: "Chirp"
}
